@import 'constants';

.container {
  display: flex;
  flex-direction: column;
  margin: 1vh 0;

  .inputHeader {
    display: flex;
    justify-content: space-between;

    span {
      font-family: Poppins;
      font-weight: 100;
      font-size: 12px;
      margin-bottom: 3px;

      &.forgotPassword {
        color: #70c5c1;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .inputContainer {
    margin-top: 0;
  }

  .input {
    font-family: Poppins;
    border: 1px solid $rove-dark-black;
    padding: 1vh 1vw;

    &:focus-visible {
      outline: 0;
    }
  }
}
