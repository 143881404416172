@import 'constants';
@import 'mixins';

.closeButton {
  cursor: pointer;
  transition: 0.2s all linear;

  &:hover {
    transform: scale(1.3);
  }
}