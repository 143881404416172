@import 'constants';
.button {
  color: $rove-white;
  border: 0;
  padding: 1.5vh 5vw;
  margin: 1vh 0;
  font-family: Poppins;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  .icon {
    display: flex;
    margin-right: 1vw;
  }

  &:hover {
    cursor: pointer;
  }
}

.facebook {
  background: #415893;
}

.google {
  background: #5080e6;
}
