@import 'constants';
@import 'mixins';

.container {
  min-height: 100vh !important;
  min-width: 100%;
  background-size: auto 100vh;
  background-position: center;

  .headerContainer {
    margin-bottom: 10vh;
  }

  .logoContainer {
    margin: 3vh 1vw;
    padding: 1vh 1vw;

    @include media($sm...) {
      margin: 1vh 4vw;
    }
  }

  .loginContainer {
    @include media($md...) {
      margin: 0 15vw;
    }

    @include media($lg...) {
      margin: 0 20vw;
    }

    @include media($xl...) {
      margin: 0 10vw;
    }
  }
}
