@import 'mixins';
@import 'constants';

.formContainer {
  display: flex;
  flex-direction: column;
  padding: 7vh 7vw;

  @include media($sm...) {
    flex: 1;
    padding: 7vh 5vw;
  }

  .title {
    font-family: Poppins;
    font-size: 45px;
    letter-spacing: 1px;
    line-height: 75px;
    text-align: center;

    @include media($sm...) {
      font-size: 30px;
    }
  }

  .subtitle {
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 3vh;

    @include media($sm...) {
      font-size: 18px;
    }
  }

  .word {
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 1vh;
  }

  .signUp {
    text-align: center;
    margin: 2vh;
    font-size: 12px;
    line-height: 18px;
    font-family: Poppins;

    a {
      font-family: Poppins;
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .loginContainer {
    margin-top: 10px;
  }

  .error {
    color: change-color($color: red, $alpha: 0.5);
    font-size: 14px;
    text-align: center;
  }
}
