@import 'constants';
@import 'mixins';

.container {
  border: 1px solid $rove-dark-black;
  background-color: $rove-white;
  font-family: Poppins, sans-serif;
  position: relative;

  @include media($sm...) {
    border: 0;
    background-color: #ffffff00;
  }

  .closeButton {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
